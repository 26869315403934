import { useParams } from "react-router-dom";
import { getShare } from "../data/share";
import React from "react";
import Annotation from "./annotation";
import TrackHeader from "./trackHeader";

import LinkFooter from "../LinkFooter/LinkFooter";
import "../App.css";
import { useNavigate } from "react-router-dom";

const Share = () => {
  let { id } = useParams();
  const [share, setShare] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    // invalid url will trigger an 404 error
    getShare(id)
      .then((response) => {
        setShare(response.data);
      })
      .catch((error) => {
        console.log(error);
        navigate("/");
      });
  }, [id, navigate]);

  return (
    <div className="App-header" >
      <div style={{ maxWidth: "800px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <TrackHeader
            title={share?.track?.original_title}
            imageUrl={share?.track?.poster_path ? `https://image.tmdb.org/t/p/w500${share?.track?.poster_path}`: ''}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
            padding: 10,
          }}
        >
          {share?.annotations?.map((item, index) => {
            // console.log(item);
            return <Annotation key={index} {...item} />;
          })}
        </div>

        <LinkFooter seeMore showLogo showAppStoreButtons/>
      </div>
    </div>
  );
};
export default Share;
