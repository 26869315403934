import Axios from "axios";

// const mainAPIURL = "http://192.168.1.217:4000/api"; //http://localhost:4000/api
const mainAPIURL =
  "https://0drp6be6d8.execute-api.ca-central-1.amazonaws.com/dev/api";

const getShare = async (shareId) => Axios.get(`${mainAPIURL}/public/share/${shareId}`);
// {
//     return 
// //   const data = 
//     // .get(`https://api.popupfeed.com/api/public/share/${shareId}`)
//     // .get(`http://localhost:4000/api/public/share/${shareId}`);
//     // .get(`https://0drp6be6d8.execute-api.ca-central-1.amazonaws.com/dev/api/public/share/${shareId}`)
//     // .then((response) => {
//     //   console.log("hello", response.data);
//     //   data = response.data;
//     // });

  
// };

export { getShare };
