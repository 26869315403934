const TrackHeader = ({ title, imageUrl }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={imageUrl}
        style={{
          height: 150,
          width: 100,
          backgroundColor: "darkgrey",
        }}
        alt={title}
      />
      <span>{title}</span>
    </div>
  );
};

export default TrackHeader;
