const LinkFooter = ({ seeMore, showLogo, showAppStoreButtons }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {showLogo && (
        <span className="logo">
          {seeMore && (
            <span style={{ verticalAlign: "super" }}>See more on </span>
          )}
          <span className="PopUp">PopUp</span>
          <span className="Feed">Feed</span>
        </span>
      )}
      {showAppStoreButtons && (
        <>
          <a
            href="https://apps.apple.com/us/app/popupfeed/id1600545788?itsct=apps_box_badge&amp;itscg=30200"
            style={{
              display: "inline-block",
              overflow: "hidden",
              borderRadius: "13px",
              width: "250px",
              height: "83px",
            }}
          >
            <img
              src="./badge_apple.svg"//"https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1677110400"
              alt="Download on the App Store"
              style={{ borderRadius: "13px", width: "250px", height: "83px" }}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.cguest.annotate&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              alt="Get it on Google Play"
              src="./badge_google.png"
              style={{ width: "20rem" }}
            />
          </a>
        </>
      )}
    </div>
  );
};

export default LinkFooter;
