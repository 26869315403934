import msToTime from "../util/msToTime";

const Annotation = ({
  annotationType,
  startMS,
  endMS,
  id,
  text,
  trackId,
  // imgUrl, // remove this
}) => (
  <div
    style={{
      backgroundColor: "darkseagreen",
      borderRadius: 20,
      margin: 10,
      borderColor: "green",
    }}
  >
    <div style={{ padding: 5, fontSize: "1rem", color: "black" }}>
      {msToTime(startMS)} - {annotationType}
    </div>
    <div style={{ padding: 5, fontSize: "1rem", color: "black" }}>{text}</div>
    {/* {imgUrl && <img src={imgUrl} />} */}
  </div>
);

export default Annotation;
