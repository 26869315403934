import "./App.css";
import LinkFooter from "./LinkFooter/LinkFooter";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <span className="logo">
          <span className="PopUp">PopUp</span>
          <span className="Feed">Feed</span>
        </span> */}
        <LinkFooter showLogo={true} showAppStoreButtons={true} />
        <a href="mailto:info@popupfeed.com" className="email">
          Email for more Info
        </a>
      </header>
    </div>
  );
}

export default App;
