import "../App.css";
import LinkFooter from "../LinkFooter/LinkFooter";

function deleteUser() {
  return (
    <div className="App">
      <header className="App-header">
        <LinkFooter showLogo={true} />
        <p>
          To delete your user and all user data, Login into PopUpFeed and access
          "Delete your user account" from the account screen.
        </p>

        <img
          alt="Delete Screenshot"
          src="./delete.jpg"
          style={{ width: 300, paddingBottom: 20 }}
        />
        <LinkFooter showAppStoreButtons={true} />
      </header>
    </div>
  );
}

export default deleteUser;
